import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Humberger from "../../components/humberger/Humberger";
import Header from "../../components/header/Header";
import Hero from "../../components/hero/Hero";
const PageLayout = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Humberger />
      <Header />
      <Hero />
      <Outlet />
      <Footer />
    </>
  );
};

export default PageLayout;
