import React, { useEffect, useState } from "react";
import Breadcrumb from "../../img/breadcrumb.jpg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useAxios from "../../utils/axiosInstance";
import NoImage from "../../img/noimage.png";
import { initialWishlistState } from "../../reduxStore/Wishlist";
import { basePrice } from "../../utils/helpers";

const WishList = () => {
  const axios = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { wishlistLength, wishlist } = useSelector((state) => state.Wishlist);
  const [backgroundImages, setBackgroundImages] = useState({});

  const getWishlists = async () => {
    try {
      const response = await axios.get("/wishlist/get_wish_list");
      const result = response?.data;
      dispatch(initialWishlistState({ wishlist: result?.data || [] }));
    } catch (error) {}
  };

  const removeFromWishlist = async (product_id) => {
    try {
      const response = await axios.post("/wishlist/add_to_wishlist", {
        product_id,
      });
      const result = response?.data;
      getWishlists();
    } catch (error) {}
  };

  const getBaseProductImage = async (productId) => {
    try {
      const response = await axios.get(
        `/products/product_base_image/${productId}`
      );
      const base64Image = response?.data?.data;
      return base64Image ? base64Image : NoImage;
    } catch (error) {
      // console.error("Error fetching base image:", error);
      return NoImage;
    }
  };

  const fetchImages = async () => {
    const images = {};
    await Promise.all(
      wishlist?.map(async (product) => {
        if (product?.id) {
          const imageUrl = await getBaseProductImage(product.id);
          images[product.id] = imageUrl;
        }
      })
    );
    setBackgroundImages(images);
  };

  useEffect(() => {
    fetchImages();
  }, [wishlist.length]);

  return (
    <>
      <section
        className="breadcrumb-section set-bg"
        style={{ backgroundImage: `url(${Breadcrumb})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="breadcrumb__text">
                <h2>Wish List</h2>
                <div className="breadcrumb__option">
                  <Link to={"/"}>Home</Link>
                  <span>Wish List</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="shoping-cart spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="shoping__cart__table">
                <table>
                  <thead>
                    <tr>
                      <th className="shoping__product">Products</th>
                      <th>Total</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {wishlist?.map((product, index) => (
                      <tr key={index}>
                        <td
                          className="shoping__cart__item hover-cursor-pointer"
                          onClick={() => {
                            navigate(
                              `/shop_details/${product?.product_full_name?.replace(
                                /[ /]+/g,
                                "_"
                              )}/${product?.id}`
                            );
                          }}
                        >
                          <img
                            src={backgroundImages[product?.id] || NoImage}
                            alt=""
                          />
                          <h5>{product?.product_name}</h5>
                        </td>
                        <td className="shoping__cart__total">
                          <div className="price-wrapper">
                            <h5>₹&nbsp;{basePrice(product, 1)}</h5>
                            {/* {Number(product?.product_discount) > 0 ? (
                              <>
                                <span className="original-price">
                                  ₹&nbsp;{product?.product_price}
                                </span>
                                <span className="text-success">
                                  {product?.product_discount}
                                  {product?.product_discount_type === 0 ? (
                                    <span>%</span>
                                  ) : (
                                    <span>₹</span>
                                  )}{" "}
                                  off
                                </span>
                              </>
                            ) : (
                              ""
                            )} */}
                          </div>
                        </td>
                        <td className="shoping__cart__item__close">
                          <span
                            className="icon_close"
                            onClick={() => removeFromWishlist(product?.id)}
                          ></span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WishList;
