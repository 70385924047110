import { configureStore } from "@reduxjs/toolkit";
import Auth from "./Auth";
import Cart from "./Cart";
import Setting from "./Setting";
import Wishlist from "./Wishlist";

export const store = configureStore({
  reducer: {
    Auth,
    Cart,
    Setting,
    Wishlist,
  },
});
