import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAxios from "../../utils/axiosInstance";
import NoImage from "../../img/noimage.png";
import Logout from "./Logout";

function Orders() {
  const axios = useAxios();
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Fetch orders
  const fetchOrders = async () => {
    try {
      const response = await axios.get("/order/get_my_orders");
      if (response?.data?.success) {
        setOrders(response?.data?.data || []);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const [backgroundImages, setBackgroundImages] = useState({});

  // Fetch product base image
  const getBaseProductImage = async (productId) => {
    try {
      const response = await axios.get(`/products/product_base_image/${productId}`);
      const base64Image = response?.data?.data;
      return base64Image ? base64Image : NoImage;  // Fallback to NoImage
    } catch (error) {
      console.error("Error fetching base image:", error);
      return NoImage; // Return fallback image in case of an error
    }
  };

  useEffect(() => {
    const fetchImages = async () => {
      const images = {};
      const items = orders.flatMap(order => JSON.parse(order.items || "[]"));
      
      // Fetch images for all items
      await Promise.all(
        items.map(async (item) => {
          if (item?.product_id) {
            const imageUrl = await getBaseProductImage(item.product_id);
            images[item.product_id] = imageUrl; // Store image by product_id
          }
        })
      );

      setBackgroundImages(images);  // Set images once all are fetched
    };

    if (orders.length > 0) {
      fetchImages();  // Fetch images when orders are loaded
    }
  }, [orders]);  // Dependency array to re-fetch when orders change

  // Pagination Logic
  const indexOfLastOrder = currentPage * itemsPerPage;
  const indexOfFirstOrder = indexOfLastOrder - itemsPerPage;
  const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Toggle row expansion for items visibility
  const toggleRow = (id) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  // Handle click to navigate to order details
  const handleClick = (orderId) => {
    navigate(`/profile/orders/orderdetails/${orderId}`);
  };

  return (
    <div className="section content">
      <div className="container">
        <div className="row mb-4 align-items-center">
          <div className="col-lg-6">
            <h4 className="font-weight-bold heading">Your Orders</h4>
          </div>
        </div>

        <div className="row mb-5">
          {/* Sidebar */}
          <div className="col-lg-3">
            <ul className="list-group tabcss">
              <li className="list-group-item">
                <Link to="/profile">
                  <i className="fa fa-user-circle-o" style={{ marginRight: "5px" }}></i>
                  My Profile
                </Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/favourite">
                  <i className="fa fa-heart" style={{ marginRight: "5px" }}></i>
                  Wishlist
                </Link>
              </li>
              <li className="list-group-item active1">
                <Link to="/profile/orders">
                  <i className="fa fa-shopping-bag" style={{ marginRight: "5px" }}></i>
                  Orders
                </Link>
              </li>
              <Logout />
            </ul>
          </div>

          {/* Orders List */}
          <div className="col-lg-9">
            {currentOrders.length > 0 ? (
              <div className="row">
                {currentOrders.map((order) => {
                  const items = JSON.parse(order?.items || "[]");
                  const payment = JSON.parse(order?.payment || "{}");

                  return (
                    <div className="col-12 mb-4" key={order.id}>
                      <div
                        className="card"
                        onClick={() => handleClick(order.id)} // Navigate to order details page
                        style={{ cursor: "pointer" }} // To indicate the card is clickable
                      >
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <h5 className="card-title">{`${order.invoice_number_prefix} ${order.invoice_number}`}</h5>
                              <p className="card-text">Date: {order.invoice_date}</p>
                              <p className="card-text">
                                Total: ₹ {payment?.payment_amount || "N/A"}
                              </p>
                              <span
                                className={`badge ${order.status === 1 ? "bg-success" : "bg-danger"}`}
                                style={{color:'#fff'}}
                              >
                                {order.status === 1 ? "Completed" : "Failed"}
                              </span>
                            </div>
                            <div>
                              <button
                                className="btn btn-info btn-sm"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleRow(order.id);
                                }}
                              >
                                {expandedRows[order.id] ? "Hide Items" : "View Items"}
                              </button>
                            </div>
                          </div>
                          {expandedRows[order.id] && (
                            <div className="mt-3">
                              {/* <h6>Items:</h6> */}
                              <ul>
                                {items.map((item) => (
                                  <li key={item.id} style={{ display: 'flex', alignItems: 'center', justifyContent: '', paddingTop:'10px'}}>
                                  <div
                                    className="featured__item__pic set-bg"
                                    style={{
                                      backgroundImage: `url(${backgroundImages[item.product_id] || NoImage})`,
                                      width: '50px', // Adjust width as needed
                                      height: '50px', // Adjust height as needed
                                      backgroundSize: 'cover', // Ensure the image fits correctly
                                      backgroundPosition: 'center',
                                    }}
                                  ></div>
                                  <div style={{ marginLeft: '10px' }}>
                                    <span>{item.product_variant_name} - Qty: {item.quantity}</span>
                                    <div>Price: ₹{item.price}</div>
                                  </div>
                                </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p>No orders found.</p>
            )}

            {/* Pagination */}
            <div className="d-flex justify-content-center mt-4">
              <nav>
                <ul className="pagination">
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={() => paginate(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                  </li>
                  {Array.from({
                    length: Math.ceil(orders.length / itemsPerPage),
                  }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
                    >
                      <button
                        className="page-link"
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={() => paginate(currentPage + 1)}
                      disabled={currentPage === Math.ceil(orders.length / itemsPerPage)}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Orders;
