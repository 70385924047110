import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PageLayout from "../layouts/pageLayout/PageLayout";
import AuthLayout from "../layouts/authLayout/AuthLayout";
import Home from "../pages/home/Home";
import Login from "../pages/auth/login/Login";
import Register from "../pages/auth/register/Register";
import ShopGrid from "../pages/shopGrid/ShopGrid";
import ShopDetails from "../pages/shopDetails/ShopDetails";
import ShoppingCart from "../pages/shoppingCart/ShoppingCart";
import Checkout from "../pages/checkout/Checkout";
import Contact from "../pages/contact/Contact";
import useAxios from "../utils/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../reduxStore/Auth";
import {
  initialCartState,
  localStorageInitailCartState,
  removeAllCartItems,
} from "../reduxStore/Cart";
import About from "../pages/about/About";
import WhoWeAre from "../pages/whoWeAre/WhoWeAre";
import PrivacyPolicy from "../pages/privacyPolicy/PrivacyPolicy";
import DeliveryInformation from "../pages/deliveryInformation/DeliveryInformation";
import OurServices from "../pages/ourServices/OurServices";
import TermsCondtions from "../pages/termsCondtions/TermsCondtions";
import WishList from "../pages/wishList/WishList";
import { initialWishlistState } from "../reduxStore/Wishlist";
import SendEmail from "../pages/forgot/SendEmail";
import Profile from "../pages/profile/Profile";
import Favourite from "../pages/profile/Favourite";
import Orders from "../pages/profile/Orders";
import OrderDetails from "../pages/profile/OrderDetails";
import ProtectedRoute from "./ProtectedRoute";
import AuthProtectedRoute from "./AuthProtectedRoute";
const Router = () => {
  const axios = useAxios();
  const [loading, setLoading] = useState(true);
  const { isLoggedIn } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();

  const getUserProfile = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/user/user_profile");
      const result = response?.data;
      localStorage.setItem("token", result?.token);
      dispatch(login({ user: result?.data }));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const addCartItems = async (items) => {
    try {
      const response = await axios.post("/cart/post_bulk_cart_items", {
        cartItems: items,
      });
      const result = response?.data;
      dispatch(removeAllCartItems());
      getCartItems();
    } catch (error) {}
  };

  const getCartItems = async () => {
    try {
      const response = await axios.get("/cart/get_cart_items");
      const result = response?.data;
      dispatch(initialCartState({ cartProducts: result?.data || [] }));
    } catch (error) {}
  };

  const getWishlists = async () => {
    try {
      const response = await axios.get("/wishlist/get_wish_list");
      const result = response?.data;
      dispatch(initialWishlistState({ wishlist: result?.data || [] }));
    } catch (error) {}
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  useEffect(() => {
    if (!loading && isLoggedIn) {
      const items = JSON.parse(localStorage.getItem("cartProducts")) || [];
      if (items?.length) {
        addCartItems(items);
      } else {
        getCartItems(); // Only call getCartItems if there are no cart items in localStorage
      }
    }
    if (!loading && !isLoggedIn)
      dispatch(
        localStorageInitailCartState({
          cartProducts: JSON.parse(localStorage.getItem("cartProducts")) || [],
        })
      );
  }, [isLoggedIn, loading]);

  useEffect(() => {
    if (!loading && isLoggedIn) getWishlists();
  }, [isLoggedIn, loading]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <PageLayout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/shop_grid/:category_name/:category_id",
          element: <ShopGrid />,
        },
        {
          path: "/shop_details/:product_name/:product_id",
          element: <ShopDetails />,
        },
        {
          path: "/shopping_cart",
          element: <ShoppingCart />,
        },
        {
          path: "/wish_list",
          element: (
            <ProtectedRoute isLoggedIn={isLoggedIn} loading={loading}>
              <WishList />
            </ProtectedRoute>
          ),
        },
        {
          path: "/checkout",
          element: (
            <ProtectedRoute isLoggedIn={isLoggedIn} loading={loading}>
              <Checkout />
            </ProtectedRoute>
          ),
        },
        {
          path: "/profile",
          element: (
            <ProtectedRoute isLoggedIn={isLoggedIn} loading={loading}>
              <Profile />
            </ProtectedRoute>
          ),
        },
        {
          path: "/profile/favourite",
          element: (
            <ProtectedRoute isLoggedIn={isLoggedIn} loading={loading}>
              <Favourite />
            </ProtectedRoute>
          ),
        },
        {
          path: "/profile/orders",
          element: (
            <ProtectedRoute isLoggedIn={isLoggedIn} loading={loading}>
              <Orders />
            </ProtectedRoute>
          ),
        },
        {
          path: "/profile/orders/orderdetails/:id",
          element: (
            <ProtectedRoute isLoggedIn={isLoggedIn} loading={loading}>
              <OrderDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: "/contact",
          element: <Contact />,
        },
        {
          path: "/about_us",
          element: <About />,
        },
        {
          path: "/who_we_are",
          element: <WhoWeAre />,
        },
        {
          path: "/privacy_policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "/delivery_information",
          element: <DeliveryInformation />,
        },
        {
          path: "/our_services",
          element: <OurServices />,
        },
        {
          path: "/terms_conditions",
          element: <TermsCondtions />,
        },
        {
          path: "/auth/login",
          element: (
            <AuthProtectedRoute isLoggedIn={isLoggedIn} loading={loading}>
              <Login />
            </AuthProtectedRoute>
          ),
        },
        {
          path: "/auth/register",
          element: (
            <AuthProtectedRoute isLoggedIn={isLoggedIn} loading={loading}>
              <Register />
            </AuthProtectedRoute>
          ),
        },
        {
          path: "/auth/forgot",
          element: (
            <AuthProtectedRoute isLoggedIn={isLoggedIn} loading={loading}>
              <SendEmail />
            </AuthProtectedRoute>
          ),
        },
      ],
    },
    // {
    //   path: "/auth",
    //   element: <AuthLayout />,
    //   children: [
    //     { path: "/auth/login", element: <Login /> },
    //     { path: "/auth/register", element: <Register /> },
    //   ],
    // },
  ]);
  return <RouterProvider router={router} />;
};

export default Router;
