import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import NoImage from "../../img/noimage.png";
import Breadcrumb from "../../img/breadcrumb.jpg";
import useAxios from "../../utils/axiosInstance";
import { basePrice } from "../../utils/helpers";

const ShopGrid = () => {
  const axios = useAxios();
  const navigate = useNavigate();
  const { category_name, category_id } = useParams();

  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});
  const [backgroundImages, setBackgroundImages] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [priceRange, setPriceRange] = useState([0, 1000]);
  const [maxPrice, setMaxPrice] = useState(1000);

  const ITEMS_PER_PAGE = 9;

  const visibleFilters = {
    board: "board",
    ink_color: "ink color",
    language: "language",
    no_of_pages: "no of pages",
    product_back_cover: "back cover",
    product_class_grade: "class grade",
    product_color: "color",
    product_company_brand: "Company Brand",
    product_country_origin: "country origin",
    product_edition: "edition",
    product_packer: "packer",
    product_size: "size",
    product_unit_of_measurement: "unit of measurement",
    ruling: "ruling",
    subject: "subject",
    variation_options: "variation options",
    writer: "writer",
  };

  const getCategoryProducts = async () => {
    try {
      const response = await axios.get(
        `/products/get_product_by_subCategory?category_id=${category_id}`
      );
      const result = response?.data?.data || [];
      setProducts(result);
      extractFilters(result);
      setMaxPrice(Math.max(...result.map((p) => p.product_price || 0), 0));
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const extractFilters = (products) => {
    const filters = {};
    products.forEach((product) => {
      for (const key in product) {
        if (product[key] && Object.keys(visibleFilters).includes(key)) {
          if (!filters[key]) filters[key] = new Set();
          filters[key].add(product[key]);
        }
      }
    });
    setFilters(filters);
  };

  const handleMultiSelectChange = (key, value) => {
    setSelectedFilters((prev) => {
      const currentValues = prev[key] || [];
      return {
        ...prev,
        [key]: currentValues.includes(value)
          ? currentValues.filter((v) => v !== value)
          : [...currentValues, value],
      };
    });
  };

  const applyFilters = () => {
    return products.filter((product) =>
      Object.entries(selectedFilters).every(([key, values]) =>
        values.length ? values.includes(product[key]) : true
      )
    ).filter(
      (product) =>
        product.product_price >= priceRange[0] &&
        product.product_price <= priceRange[1]
    );
  };

  const paginatedProducts = () => {
    const filteredProducts = applyFilters();
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    return filteredProducts.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  };

  const totalPages = Math.ceil(applyFilters().length / ITEMS_PER_PAGE);

  useEffect(() => {
    const fetchImages = async () => {
      const images = {};
      await Promise.all(
        products.map(async (product) => {
          if (product?.id) {
            const imageUrl = await getBaseProductImage(product.id);
            images[product.id] = imageUrl;
          }
        })
      );
      setBackgroundImages(images);
    };
    fetchImages();
  }, [products]);

  const getBaseProductImage = async (productId) => {
    try {
      const response = await axios.get(
        `/products/product_base_image/${productId}`
      );
      const base64Image = response?.data?.data;
      return base64Image || NoImage;
    } catch {
      return NoImage;
    }
  };

  useEffect(() => {
    if (category_id) getCategoryProducts();
  }, [category_id]);

  return (
    <>
      <section
        className="breadcrumb-section set-bg"
        style={{ backgroundImage: `url(${Breadcrumb})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="breadcrumb__text">
                <h2>{category_name?.split("-").join(" ")}</h2>
                <div className="breadcrumb__option">
                  <Link to="/">Home</Link>
                  <span>{category_name?.split("-").join(" ")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="product spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-5">
              <div className="sidebar">
                <div className="sidebar__item">
                  <h5>Price Range</h5>
                  <input
                    type="range"
                    min="0"
                    max={maxPrice}
                    value={priceRange[0]}
                    onChange={(e) =>
                      setPriceRange([Number(e.target.value), priceRange[1]])
                    }
                  />
                  <input
                    type="range"
                    min="0"
                    max={maxPrice}
                    value={priceRange[1]}
                    onChange={(e) =>
                      setPriceRange([priceRange[0], Number(e.target.value)])
                    }
                  />
                  <div>
                    ₹{priceRange[0]} - ₹{priceRange[1]}
                  </div>
                </div>
                {Object.entries(filters).map(([key, values]) => (
                  <div className="sidebar__item" key={key}>
                    <span style={{fontSize:'18px', fontWeight:'600'}}>{visibleFilters[key]}</span>
                    <div className="multi-select" style={{maxHeight:'120px', overflowX:"auto", marginTop:'10px'}}>
                      {[...values].map((value) => (
                        <label key={value}>
                          <input
                            type="checkbox"
                            value={value}
                            onChange={() => handleMultiSelectChange(key, value)}
                            checked={
                              selectedFilters[key]?.includes(value) || false
                            }
                          />
                          {value}
                        </label>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="col-lg-9 col-md-7">
              <div className="row">
                {paginatedProducts().map((product) => (
                  <div key={product?.id} className="col-lg-4 col-md-6 col-sm-6">
                    <div className="product__item">
                      <div
                        className="product__item__pic set-bg"
                        style={{
                          backgroundImage: `url(${
                            backgroundImages[product.id] || NoImage
                          })`,
                        }}
                        onClick={() =>
                          navigate(
                            `/shop_details/${product?.product_full_name?.replace(
                              /[ /]+/g,
                              "_"
                            )}/${product?.id}`
                          )
                        }
                      ></div>
                      <div className="product__item__text">
                        <h6>{product?.product_name}</h6>
                        <h5>₹ {basePrice(product, 1)}</h5>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="product__pagination">
  {currentPage > 1 && (
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault();
        setCurrentPage(currentPage - 1);
      }}
    >
      Prev
    </a>
  )}

  {[...Array(totalPages)].map((_, index) => {
    const page = index + 1;

    // Logic for displaying the page buttons
    if (
      page === 1 || // Always show the first page
      page === totalPages || // Always show the last page
      (page >= currentPage - 1 && page <= currentPage + 1) || // Show pages near the current page
      page === 2 || // Show the second page
      page === totalPages - 1 // Show the second last page
    ) {
      return (
        <a
          href="#"
          key={page}
          className={currentPage === page ? "active" : ""}
          onClick={(e) => {
            e.preventDefault();
            setCurrentPage(page);
          }}
        >
          {page}
        </a>
      );
    }

    // Add ellipses for skipped pages
    if (
      page === currentPage - 2 || // Add ellipses before the current page range
      page === currentPage + 2 // Add ellipses after the current page range
    ) {
      return (
        <span key={page} className="ellipsis">
          ...
        </span>
      );
    }

    return null; // Hide all other pages
  })}

  {currentPage < totalPages && (
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault();
        setCurrentPage(currentPage + 1);
      }}
    >
      Next
    </a>
  )}
</div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShopGrid;
