import React, { useEffect, useState } from "react";
import Breadcrumb from "../../img/breadcrumb.jpg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useAxios from "../../utils/axiosInstance";
import NoImage from "../../img/noimage.png";
import { initialWishlistState } from "../../reduxStore/Wishlist";
import Logout from "./Logout";
import { basePrice } from "../../utils/helpers";

function Favourite() {
  const axios = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { wishlistLength, wishlist } = useSelector((state) => state.Wishlist);
  const [backgroundImages, setBackgroundImages] = useState({});

  const getWishlists = async () => {
    try {
      const response = await axios.get("/wishlist/get_wish_list");
      const result = response?.data;
      dispatch(initialWishlistState({ wishlist: result?.data || [] }));
    } catch (error) {}
  };

  const removeFromWishlist = async (product_id) => {
    try {
      const response = await axios.post("/wishlist/add_to_wishlist", {
        product_id,
      });
      const result = response?.data;
      getWishlists();
    } catch (error) {}
  };

  const getBaseProductImage = async (productId) => {
    try {
      const response = await axios.get(
        `/products/product_base_image/${productId}`
      );
      const base64Image = response?.data?.data;
      return base64Image ? base64Image : NoImage;
    } catch (error) {
      // console.error("Error fetching base image:", error);
      return NoImage;
    }
  };

  const fetchImages = async () => {
    const images = {};
    await Promise.all(
      wishlist?.map(async (product) => {
        if (product?.id) {
          const imageUrl = await getBaseProductImage(product.id);
          images[product.id] = imageUrl;
        }
      })
    );
    setBackgroundImages(images);
  };

  useEffect(() => {
    fetchImages();
  }, [wishlist.length]);

  return (
    <>
      <div className="section content">
        <div className="container ">
          <div className="row mb-4 align-items-center">
            <div className="col-lg-6">
              <h4 className="font-weight-bold heading">Your Favourite</h4>
            </div>
            <div className="col-lg-6 text-lg-end"></div>
          </div>

          <div className="row mb-5">
            {/* Sidebar for large screens */}
            <div className="col-lg-3">
              <ul className="list-group tabcss">
                <li className="list-group-item ">
                  <Link to="/profile">
                    {" "}
                    <i
                      className="fa fa-user-circle-o"
                      style={{ marginRight: "5px" }}
                    ></i>
                    My Profile
                  </Link>
                </li>
                <li className="list-group-item active1">
                  <Link to="/profile/favourite">
                    {" "}
                    <i
                      className="fa fa-heart"
                      style={{ marginRight: "5px" }}
                    ></i>
                    Wishlist
                  </Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/orders">
                    <i
                      className="fa fa-shopping-bag"
                      style={{ marginRight: "5px" }}
                    ></i>
                    Orders
                  </Link>
                </li>
                <Logout />
              </ul>
            </div>

            <div className="col-lg-9">
              <section className="spad">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="shoping__cart__table">
                        <table>
                          <thead>
                            <tr>
                              <th className="shoping__product">Products</th>
                              <th>Total</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {wishlist?.map((product, index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    paddingTop: "15px",
                                    paddingBottom: "15px",
                                  }}
                                  className="shoping__cart__item hover-cursor-pointer"
                                  onClick={() => {
                                    navigate(
                                      `/shop_details/${product?.product_full_name?.replace(
                                        /[ /]+/g,
                                        "_"
                                      )}/${product?.id}`
                                    );
                                  }}
                                >
                                  <img
                                    src={
                                      backgroundImages[product?.id] || NoImage
                                    }
                                    alt=""
                                    style={{ width: "80px", height: "80px" }}
                                  />
                                  <h5>{product?.product_name}</h5>
                                </td>
                                <td className="shoping__cart__total">
                                  ₹&nbsp;{basePrice(product, 1)}
                                </td>
                                <td className="shoping__cart__item__close">
                                  <span
                                    className="fa fa-trash"
                                    onClick={() =>
                                      removeFromWishlist(product?.id)
                                    }
                                  ></span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Favourite;
