import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import useAxios from "../../utils/axiosInstance";
import NoImage from "../../img/noimage.png"; // Assuming this is the fallback image
import Logout from "./Logout"; // If needed, keep this or remove it if not used

function OrderDetails() {
  const { id } = useParams(); // Get the `id` from the URL params
  const axios = useAxios();
  const navigate = useNavigate();
  const [orderdetails, setOrderdetails] = useState([]);
  const [clientDetails, setClientDetails] = useState({});
  const [items, setItems] = useState([]);

  // Fetch order details based on the `id` from params
  const fetchOrderDetails = async () => {
    try {
      const response = await axios.get(`/order/get_order_by_id/${id}`);
      console.log(response, "response");

      if (response?.data?.success) {
        setOrderdetails(response?.data?.data || {});
        setClientDetails(
          JSON.parse(response?.data?.data[0]?.client_details || "{}")
        );
        setItems(JSON.parse(response?.data?.data[0]?.items || "[]"));
      }
      console.log(clientDetails, "client details");
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };
  console.log(items, "items data");

  useEffect(() => {
    if (id) {
      fetchOrderDetails();
    }
  }, [id]);

  const [backgroundImages, setBackgroundImages] = useState({});

  const getBaseProductImage = async (productId) => {
    try {
      const response = await axios.get(
        `/products/product_base_image/${productId}`
      );
      const base64Image = response?.data?.data;
      return base64Image ? base64Image : NoImage;
    } catch (error) {
      console.error("Error fetching base image:", error);
      return NoImage;
    }
  };

  useEffect(() => {
    const fetchImages = async () => {
      const images = {};
      const items = orderdetails.flatMap((orderdetails) =>
        JSON.parse(orderdetails.items || "[]")
      );

      // Fetch images for all items
      await Promise.all(
        items.map(async (item) => {
          if (item?.product_id) {
            const imageUrl = await getBaseProductImage(item.product_id);
            images[item.product_id] = imageUrl; // Store image by product_id
          }
        })
      );

      setBackgroundImages(images); // Set images once all are fetched
    };
    if (orderdetails.length > 0) {
      fetchImages(); // Fetch images when orders are loaded
    }
  }, [orderdetails]);

  const downloadInvoice = () => {
    const invoiceUrl = `/order/download_invoice/${id}`;
    window.location.href = invoiceUrl;
  };

  const handleClick = (orderId) => {
    navigate(`/profile/orders/orderdetails/${orderId}`);
  };

  return (
    <div className="section content">
      <div className="container ">
        <div className="row mb-4 align-items-center">
          <div className="col-lg-6">
            <h4 className="font-weight-bold heading">Order Details</h4>
          </div>
        </div>

        <div className="row mb-5">
          {/* Sidebar for large screens */}
          <div className="col-lg-3">
            <ul className="list-group tabcss">
              <li className="list-group-item ">
                <Link to="/profile">
                  <i
                    className="fa fa-user-circle-o"
                    style={{ marginRight: "5px" }}
                  ></i>
                  My Profile
                </Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/favourite">
                  <i className="fa fa-heart" style={{ marginRight: "5px" }}></i>
                  Wishlist
                </Link>
              </li>
              <li className="list-group-item active1">
                <Link to="/profile/orders">
                  <i
                    className="fa fa-shopping-bag"
                    style={{ marginRight: "5px" }}
                  ></i>
                  Orders
                </Link>
              </li>
              <Logout />
            </ul>
          </div>

          <div className="col-lg-9">
            <div className="">
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    {/* Client Details */}
                    <h5 className="mb-2">
                      <b>Client Details</b>
                    </h5>
                    <p>
                      <strong>{clientDetails.client_name}</strong>
                    </p>
                    <p>{clientDetails.client_email}</p>
                    <p>{clientDetails.client_phone}</p>
                    <p>{clientDetails.client_gst_number}</p>
                    <p>
                      <strong>Address:</strong> {clientDetails.client_address},{" "}
                      {clientDetails.client_city}, {clientDetails.client_state},
                      {clientDetails.client_country},
                      {clientDetails.client_zipcode}
                    </p>
                  </div>

                  <div className="">
                    <button
                      className="btn btn-primary"
                      onClick={downloadInvoice}
                    >
                      Download Invoice
                    </button>
                  </div>
                </div>
                {/* Order Items */}
                <h5 className="mb-3 mt-3">Items in this Order</h5>
                {items.length > 0 ? (
                  <div className="">
                    {/* <thead>
                      <tr>
                        <th>Product Name</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Total Amount</th>
                      </tr>
                    </thead> */}
                    <div>
                      {items.map((item, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            navigate(
                              `/shop_details/${item?.product_variant_name?.replace(
                                /[ /]+/g,
                                "_"
                              )}/${item?.product_id}`
                            );
                          }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "5px",
                            margin: "10px",
                            background: "#fff",
                            borderRadius: "6px",
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                            cursor: "pointer",
                          }}
                        >
                          <div
                            className="featured__item__pic set-bg"
                            style={{
                              backgroundImage: `url(${
                                backgroundImages[item.product_id] || NoImage
                              })`,
                              width: "70px",
                              height: "70px",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              borderRadius: "2px",
                            }}
                          ></div>

                          <div style={{ flex: 1, marginLeft: "10px" }}>
                            <p style={{ margin: "5px 0" }}>
                              {item.product_variant_name}
                            </p>
                            <p style={{ margin: "5px 0" }}>Qty: {item.quantity}</p>
                            <p style={{ margin: "5px 0" }}>₹ {item.amount}</p>
                          </div>
                          <div>
                            <button className="btn btn-secondary">View</button>
                          </div>
                        </div>
                      
                      ))}
                    </div>
                  </div>
                ) : (
                  <p>No items in this order.</p>
                )}
              </div>

              {/* Invoice QR Code */}
              {orderdetails.qrcode && (
                <div className="col-lg-12">
                  <h5>Invoice QR Code</h5>
                  <img
                    src={`https://api.qrserver.com/v1/create-qr-code/?data=${orderdetails.qrcode}&size=150x150`}
                    alt="Invoice QR Code"
                    width="150"
                    height="150"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;
