import React from "react";
import Breadcrumb from "../../img/breadcrumb.jpg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const TermsCondtions = () => {
  const { setting } = useSelector((state) => state.Setting);
  return (
    <>
      <section
        className="breadcrumb-section set-bg"
        style={{ backgroundImage: `url(${Breadcrumb})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="breadcrumb__text">
                <h2>Terms & Conditions</h2>
                <div className="breadcrumb__option">
                  <Link to="/">Home</Link>
                  <span>Terms & Conditions</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="terms__conditions spad">
        <div
          className="container"
          dangerouslySetInnerHTML={{
            __html: setting?.term_condition || "",
          }}
        ></div>
      </section>
    </>
  );
};

export default TermsCondtions;
