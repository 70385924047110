import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumb from "../../img/breadcrumb.jpg";
import NoImage from "../../img/noimage.png";
import useAxios from "../../utils/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCartItem,
  initialCartState,
  productQuantityChange,
} from "../../reduxStore/Cart";
import LoginModal from "../auth/login/LoginModal";

const ShoppingCart = () => {
  const axios = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cartProducts, subTotal, total } = useSelector((state) => state.Cart);
  const { isLoggedIn } = useSelector((state) => state.Auth);
  const [backgroundImages, setBackgroundImages] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleProceedToCheckout = () => {
    setShowConfirmationModal(true);
  };

  const confirmCheckout = () => {
    setShowConfirmationModal(false);
    if (!isLoggedIn) {
      // navigate("/auth/login?redirect=shopping_cart");
      setShowLoginModal(true);
    } else {
      navigate("/checkout");
    }
  };

  const cancelCheckout = () => {
    setShowConfirmationModal(false);
    navigate("/");
  };

  const canclemodel = () => {
    setShowConfirmationModal(false);
  };

  const getCartItems = async () => {
    try {
      const response = await axios.get("/cart/get_cart_items");
      const result = response?.data;
      dispatch(initialCartState({ cartProducts: result?.data || [] }));
    } catch (error) {}
  };

  const postDeleteCartItem = async (product_variant_id) => {
    try {
      const response = await axios.delete(
        `/cart/delete_cart_item/${product_variant_id}`
      );
      const result = response?.data;
      getCartItems();
    } catch (error) {}
  };

  const handleDeleteCartItem = (product_variant_id) => {
    if (isLoggedIn) {
      postDeleteCartItem(product_variant_id);
    } else {
      dispatch(deleteCartItem({ product_variant_id }));
    }
  };

  const postCartItemQuantity = async (product_variant_id, quantity) => {
    try {
      const response = await axios.post(
        `/cart/update_cart_item_quantity/${product_variant_id}`,
        { quantity }
      );
      const result = response?.data;
      getCartItems();
    } catch (error) {}
  };

  const handleQuantityChange = (product_variant_id, quantity) => {
    if (isLoggedIn) {
      postCartItemQuantity(product_variant_id, quantity);
    } else {
      dispatch(productQuantityChange({ product_variant_id, quantity }));
    }
  };

  const getBaseProductImage = async (productId) => {
    try {
      const response = await axios.get(
        `/products/product_base_image/${productId}`
      );
      const base64Image = response?.data?.data;
      return base64Image ? base64Image : NoImage;
    } catch (error) {
      // console.error("Error fetching base image:", error);
      return NoImage;
    }
  };

  const fetchImages = async () => {
    const images = {};
    await Promise.all(
      cartProducts?.map(async (product) => {
        if (product?.product_variant_id) {
          const imageUrl = await getBaseProductImage(
            product.product_variant_id
          );
          images[product.product_variant_id] = imageUrl;
        }
      })
    );
    setBackgroundImages(images);
  };

  useEffect(() => {
    fetchImages();
  }, [cartProducts.length]);

  return (
    <>
      <section
        className="breadcrumb-section set-bg"
        style={{ backgroundImage: `url(${Breadcrumb})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="breadcrumb__text">
                <h2>Shopping Cart</h2>
                <div className="breadcrumb__option">
                  <Link to="/">Home</Link>
                  <span>Shopping Cart</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="shoping-cart spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="shoping__cart__table">
                <table>
                  <thead>
                    <tr>
                      <th className="shoping__product">Products</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Total</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartProducts?.map((product, index) => (
                      <tr key={index}>
                        <td
                          className="shoping__cart__item hover-cursor-pointer"
                          onClick={() => {
                            navigate(
                              `/shop_details/${product?.product_name?.replace(
                                /[ /]+/g,
                                "_"
                              )}/${product?.product_variant_id}`
                            );
                          }}
                        >
                          <img
                            src={
                              backgroundImages[product.product_variant_id] ||
                              NoImage
                            }
                            alt=""
                          />
                          <h5>{product?.product_name}</h5>
                        </td>
                        <td className="shoping__cart__price">
                          ₹&nbsp;{product?.price}
                        </td>
                        <td className="shoping__cart__quantity">
                          <div className="quantity">
                            <div className="pro-qty">
                              <select
                                value={product?.quantity}
                                onChange={(e) => {
                                  handleQuantityChange(
                                    product?.product_variant_id,
                                    e.target.value
                                  );
                                }}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  textAlign: "center",
                                  background: "#f2f2f2",
                                  border: "none",
                                }}
                              >
                                {[...Array(10)].map((_, i) => (
                                  <option key={i + 1} value={i + 1}>
                                    {i + 1}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </td>
                        <td className="shoping__cart__total">
                          ₹&nbsp;
                          {product?.total}
                        </td>
                        <td className="shoping__cart__item__close">
                          <span
                            className="icon_close"
                            onClick={() =>
                              handleDeleteCartItem(product?.product_variant_id)
                            }
                          ></span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="shoping__cart__btns">
                <Link to={"/"} className="primary-btn cart-btn">
                  CONTINUE SHOPPING
                </Link>
                {/* <a href="#" className="primary-btn cart-btn cart-btn-right">
                  <span className="icon_loading"></span>
                  Upadate Cart
                </a> */}
              </div>
            </div>
            {/* <div className="col-lg-6">
              <div className="shoping__continue">
                <div className="shoping__discount">
                  <h5>Discount Codes</h5>
                  <form action="#">
                    <input type="text" placeholder="Enter your coupon code" />
                    <button type="submit" className="site-btn">
                      APPLY COUPON
                    </button>
                  </form>
                </div>
              </div>
            </div> */}
            <div className="col-lg-6">
              <div className="shoping__checkout">
                <h5>Cart Total</h5>
                <ul>
                  {/* <li>
                    Subtotal{" "}
                    <span>
                      ₹&nbsp;
                      {subTotal}
                    </span>
                  </li> */}
                  <li>
                    Total{" "}
                    <span>
                      ₹&nbsp;
                      {total}
                    </span>
                  </li>
                </ul>
                <span
                  className="primary-btn"
                  onClick={
                    total >= 0 && cartProducts?.length !== 0
                      ? handleProceedToCheckout
                      : undefined
                  }
                  style={{
                    background:
                      cartProducts?.length === 0 || total < 0
                        ? "#8c8c8c"
                        : "#7fad39",
                    pointerEvents:
                      cartProducts?.length === 0 || total < 0 ? "none" : "auto",
                    opacity: cartProducts?.length === 0 || total < 0 ? 0.6 : 1,
                  }}
                >
                  PROCEED TO CHECKOUT
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>


      <LoginModal
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
      />

      {showConfirmationModal && (
        <div className="confirmation-modal">
          <div className="modal-content">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4>Proceed to Checkout?</h4>
              <button
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  borderRadius: "500px",
                  border: "none",
                  background: "transparent",
                  fontSize: "1.5rem",
                  cursor: "pointer",
                }}
                onClick={canclemodel}
              >
                ×
              </button>
            </div>
            <p>Are you sure you want to proceed to checkout?</p>
            <div className="modal-buttons">
              <button className="primary-btn" onClick={cancelCheckout}>
                Continue Shopping
              </button>
              <button className="primary-btn" onClick={confirmCheckout}>
                Yes, Proceed
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShoppingCart;
