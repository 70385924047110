import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useAxios from "../../utils/axiosInstance";
const Footer = () => {
  const axios = useAxios();
  const { setting } = useSelector((state) => state.Setting);
  const [subscribersEmail, setSubscribersEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [busy, setBusy] = useState(false);

  const handleSubscribers = async (e) => {
    e.preventDefault();
    setBusy(true);
    setError("");
    setSuccess("");
    try {
      const response = await axios.post("/general/post_subscribers", {
        email: subscribersEmail,
      });
      const result = response?.data;
      setSuccess(result?.message);
      setSubscribersEmail("");
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong";
      setError(errorMessage);
    } finally {
      setBusy(false);
    }
  };

  return (
    <footer className="footer spad">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="footer__about">
              <div className="footer__about__logo">
                <Link to={"/"}>
                  <img src={setting?.image_url} alt="" />
                </Link>
              </div>
              <ul>
                <li>Address: {setting?.address}</li>
                <li>Phone: +91 {setting?.telephone}</li>
                <li>Email: {setting?.email}</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-1">
            <div className="footer__widget">
              <h6>Useful Links</h6>
              <ul>
                <li>
                  <Link to={"/about_us"}>About Us</Link>
                </li>
                <li>
                  <Link to={"/delivery_information"}>Delivery infomation</Link>
                </li>
                <li>
                  <Link to={"/terms_conditions"}>Terms & Conditions</Link>
                </li>
                <li>
                  <Link to={"/privacy_policy"}>Privacy Policy</Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Link to={"/who_we_are"}>Who We Are</Link>
                </li>
                <li>
                  <Link to={"/our_services"}>Our Services</Link>
                </li>
                <li>
                  <Link to={"/contact"}>Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="footer__widget">
              {/* <h6>Join Our Newsletter Now</h6> */}
              <p>
                Get E-mail updates about our latest shop and special offers.
              </p>
              {error && (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  {error}
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    onClick={() => setError("")}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}
              {success && (
                <div
                  className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  {success}
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    onClick={() => setSuccess("")}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}
              <form onSubmit={handleSubscribers}>
                <input
                  type="email"
                  placeholder="Enter your mail"
                  pattern="^(?!.*\.\.)[^\s@]+@[^\s@]+\.[^\s@]+(?<!\.)$"
                  title="Please enter a valid email format, no double dots, and no trailing dot."
                  required
                  value={subscribersEmail || ""}
                  onChange={(e) => setSubscribersEmail(e.target.value)}
                />
                <button type="submit" className="site-btn" disabled={busy}>
                  Subscribe
                </button>
              </form>
              <div className="footer__widget__social">
                <a href="#">
                  <i className="fa fa-facebook"></i>
                </a>
                <a href="#">
                  <i className="fa fa-instagram"></i>
                </a>
                <a href="#">
                  <i className="fa fa-twitter"></i>
                </a>
                {/* <a href="#">
                  <i className="fa fa-pinterest"></i>
                </a> */}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-lg-12">
            <div className="footer__copyright">
              <div className="footer__copyright__text">
                <p>
                  Copyright &copy; {new Date().getFullYear()} All rights
                  reserved | This template is made with{" "}
                  <i className="fa fa-heart" aria-hidden="true"></i> by{" "}
                  <a href="https://colorlib.com" target="_blank">
                    Colorlib
                  </a>
                </p>
              </div>
              <div className="footer__copyright__payment">
                <img src={PaymentItem} alt="" />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
