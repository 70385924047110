import { useDispatch } from "react-redux";
import { initialSettingState } from "./reduxStore/Setting";
import Router from "./routes/Router";
import useAxios from "./utils/axiosInstance";
import { useEffect } from "react";
function App() {
  const dispatch = useDispatch();
  const axios = useAxios();
  const getSettings = async () => {
    try {
      const response = await axios.get("/general/settings");
      const result = response?.data;
      dispatch(initialSettingState({ setting: result.data }));
    } catch (error) {}
  };

  useEffect(() => {
    getSettings();
  }, []);
  return <Router />;
}

export default App;
