import React, { useEffect, useState } from "react";
import Breadcrumb from "../../img/breadcrumb.jpg";
import Product1 from "../../img/product/product-1.jpg";
import Product2 from "../../img/product/product-2.jpg";
import Product3 from "../../img/product/product-3.jpg";
import Product7 from "../../img/product/product-7.jpg";
import NoImage from "../../img/noimage.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAxios from "../../utils/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, initialCartState } from "../../reduxStore/Cart";
import { initialWishlistState } from "../../reduxStore/Wishlist";
import { basePrice } from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginModal from "../auth/login/LoginModal";

const ShopDetails = () => {
  const axios = useAxios();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoggedIn, user } = useSelector((state) => state.Auth);
  const { product_id } = useParams();
  const [product, setProduct] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [images, setImages] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [backgroundImages, setBackgroundImages] = useState({});
  const [showLoginModal, setShowLoginModal] = useState(false);

  const getWishlists = async () => {
    try {
      const response = await axios.get("/wishlist/get_wish_list");
      const result = response?.data;
      dispatch(initialWishlistState({ wishlist: result?.data || [] }));
    } catch (error) {}
  };

  const addToWishList = async () => {
    if (!isLoggedIn) setShowLoginModal(true);
    else {
      try {
        const response = await axios.post("/wishlist/add_to_wishlist", {
          product_id,
        });
        const result = response?.data;
        toast("Wishlist Update Sucessfully!");
        getProducts();
        getWishlists();
      } catch (error) {}
    }
  };

  const getCartItems = async () => {
    try {
      const response = await axios.get("/cart/get_cart_items");
      const result = response?.data;
      dispatch(initialCartState({ cartProducts: result?.data || [] }));
    } catch (error) {}
  };

  const postAddToCart = async () => {
    try {
      const response = await axios.post("/cart/add_to_cart", {
        product_variant_id: product.id,
        quantity,
      });
      const result = response?.data;
      toast("Item Added to Cart");
      getCartItems();
    } catch (error) {}
  };

  const handleAddToCart = () => {
    if (isLoggedIn) {
      postAddToCart();
    } else {
      const cartProduct = {
        ...product,
        product_name: product?.product_name,
        product_variant_id: product.id,
        quantity, // The current quantity to add
        price: parseFloat(Number(basePrice(product, 1))).toFixed(2),
        total: parseFloat(Number(basePrice(product, 1))).toFixed(2),
      };
      dispatch(addToCart({ cartProduct }));
    }
  };

  const buyNowHandler = () => {
    handleAddToCart();
    navigate("/shopping_cart");
  };

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `/products/product_details/${product_id}${
          isLoggedIn ? `?client_id=${user?.id}` : ""
        }`
      );
      const result = response?.data;
      setProduct(result?.data);
    } catch (error) {
      navigate("/");
    }
  };

  const getImages = async () => {
    try {
      const response = await axios.get(
        `/products/product_images/${product_id}`
      );
      const result = response?.data;
      setImages(result?.data);
    } catch (error) {}
  };

  const getRelatedProducts = async () => {
    try {
      const response = await axios.get(
        `/products/get_related_products/${product_id}`
      );
      const result = response?.data;
      setRelatedProducts(result?.data || []);
    } catch (error) {}
  };

  const getBaseProductImage = async (productId) => {
    try {
      const response = await axios.get(
        `/products/product_base_image/${productId}`
      );
      const base64Image = response?.data?.data;
      return base64Image ? base64Image : NoImage;
    } catch (error) {
      // console.error("Error fetching base image:", error);
      return NoImage;
    }
  };

  useEffect(() => {
    const fetchImages = async () => {
      const images = {};
      await Promise.all(
        relatedProducts.map(async (product) => {
          if (product?.id) {
            const imageUrl = await getBaseProductImage(product.id);
            images[product.id] = imageUrl;
          }
        })
      );
      setBackgroundImages(images);
    };

    fetchImages();
  }, [relatedProducts]);

  useEffect(() => {
    if (product_id) {
      getProducts();
      getImages();
    } else navigate("/");
    return () => {
      setImages([]);
    };
  }, [product_id, isLoggedIn]);

  useEffect(() => {
    if (product_id) getRelatedProducts();
  }, [product_id]);

  return (
    <>
      <section
        className="breadcrumb-section set-bg"
        style={{ backgroundImage: `url(${Breadcrumb})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="breadcrumb__text">
                <h2>{product?.product_name}</h2>
                <div className="breadcrumb__option">
                  <Link to={"/"}>Home</Link>
                  <Link to={"/"}>
                    {product?.parent_main_category === 1
                      ? "Book"
                      : "Stationary"}
                  </Link>
                  <span>{product?.product_name}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="product-details spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="product__details__pic">
                <div className="product__details__pic__item">
                  <img
                    className="product__details__pic__item--large"
                    src={images[0] ? images[0]?.image_url : NoImage}
                    alt=""
                  />
                </div>
                <div className="product__details__pic__slider">
                  {images?.length
                    ? images.map((image, index) => (
                        <img
                          key={index}
                          src={image?.image_url || NoImage}
                          alt=""
                        />
                      ))
                    : ""}
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="product__details__text">
                <h5>{product?.product_full_name}</h5>
                <div className="product__details__price">
                  <div className="price-wrapper">
                    ₹&nbsp;{basePrice(product, 1)}
                    {Number(product?.product_discount) > 0 ? (
                      <>
                        <span className="original-price">
                          ₹&nbsp;{product?.product_price}
                        </span>
                        <span className="text-success">
                          {product?.product_discount}
                          {product?.product_discount_type === 0 ? (
                            <span>%</span>
                          ) : (
                            <span>₹</span>
                          )}{" "}
                          off
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {product?.product_short_description ? (
                  <p>{product?.product_short_description}</p>
                ) : (
                  ""
                )}
                <span
                  className="primary-btn hover-cursor-pointer"
                  onClick={handleAddToCart}
                >
                  ADD TO CARD
                </span>
                <span
                  className="primary-btn hover-cursor-pointer"
                  onClick={buyNowHandler}
                >
                  Buy Now
                </span>
                <span
                  className="heart-icon hover-cursor-pointer"
                  onClick={addToWishList}
                >
                  <i
                    className={`fa fa-heart ${
                      product?.is_favorite === 1 ? "active" : ""
                    }`}
                  />
                </span>
                <ul>
                  {product?.product_short_description && (
                    <li>
                      <h5 className="mb-3">
                        {product?.product_short_description}
                      </h5>
                    </li>
                  )}

                  {product?.product_name && (
                    <li>
                      <b>Product Name: </b>
                      <span>{product?.product_name}</span>
                    </li>
                  )}

                  {product?.product_article_number && (
                    <li>
                      <b>Article Number: </b>
                      <span>{product?.product_article_number}</span>
                    </li>
                  )}

                  {/* {product?.product_categories && (
    <li>
      <b>Categories: </b>
      <span>{product?.product_categories}</span>
    </li>
  )} */}

                  {product?.product_company_brand && (
                    <li>
                      <b>Brand: </b>
                      <span>{product?.product_company_brand}</span>
                    </li>
                  )}

                  {product?.product_description && (
                    <li>
                      <b>Description: </b>
                      <p>{product?.product_description}</p>
                    </li>
                  )}

                  {/* {product?.product_price && (
    <li>
      <b>Price: </b>
      <span>{product?.product_price ? `₹${product.product_price}` : "N/A"}</span>
    </li>
  )} */}

                  {/* {product?.product_discount_type && (
    <li>
      <b>Discount Type: </b>
      <span>{product?.product_discount_type}</span>
    </li>
  )} */}

                  {/* {product?.product_discount && (
    <li>
      <b>Discount: </b>
      <span>{product?.product_discount}%</span>
    </li>
  )} */}

                  {/* {product?.product_tax && (
    <li>
      <b>Tax: </b>
      <span>{product?.product_tax}%</span>
    </li>
  )} */}

                  {product?.product_country_origin && (
                    <li>
                      <b>Country of Origin: </b>
                      <span>{product?.product_country_origin}</span>
                    </li>
                  )}

                  {product?.product_importer && (
                    <li>
                      <b>Importer: </b>
                      <span>{product?.product_importer}</span>
                    </li>
                  )}

                  {product?.product_packer && (
                    <li>
                      <b>Packer: </b>
                      <span>{product?.product_packer}</span>
                    </li>
                  )}

                  {product?.product_unit_of_measurement && (
                    <li>
                      <b>Unit of Measurement: </b>
                      <span>{product?.product_unit_of_measurement}</span>
                    </li>
                  )}

                  {product?.product_class_grade && (
                    <li>
                      <b>Class/Grade: </b>
                      <span>{product?.product_class_grade}</span>
                    </li>
                  )}

                  {product?.product_edition && (
                    <li>
                      <b>Edition: </b>
                      <span>{product?.product_edition}</span>
                    </li>
                  )}

                  {product?.product_release_date && (
                    <li>
                      <b>Release Date: </b>
                      <span>{product?.product_release_date}</span>
                    </li>
                  )}

                  {product?.variation_options && (
                    <li>
                      <b>Variation Options: </b>
                      <span>{product?.variation_options}</span>
                    </li>
                  )}

                  {product?.product_more_details && (
                    <li>
                      <b>More Details: </b>
                      <span>{product?.product_more_details}</span>
                    </li>
                  )}

                  {product?.product_color && (
                    <li>
                      <b>Color: </b>
                      <span>{product?.product_color}</span>
                    </li>
                  )}

                  {product?.product_size && (
                    <li>
                      <b>Size: </b>
                      <span>{product?.product_size}</span>
                    </li>
                  )}

                  {product?.product_back_cover && (
                    <li>
                      <b>Back Cover: </b>
                      <span>{product?.product_back_cover}</span>
                    </li>
                  )}

                  {product?.no_of_pages && (
                    <li>
                      <b>No of Pages: </b>
                      <span>{product?.no_of_pages}</span>
                    </li>
                  )}

                  {product?.ruling && (
                    <li>
                      <b>Ruling: </b>
                      <span>{product?.ruling}</span>
                    </li>
                  )}

                  {product?.ink_color && (
                    <li>
                      <b>Ink Color: </b>
                      <span>{product?.ink_color}</span>
                    </li>
                  )}

                  {product?.board && (
                    <li>
                      <b>Board: </b>
                      <span>{product?.board}</span>
                    </li>
                  )}

                  {product?.subject && (
                    <li>
                      <b>Subject: </b>
                      <span>{product?.subject}</span>
                    </li>
                  )}

                  {product?.writer && (
                    <li>
                      <b>Writer: </b>
                      <span>{product?.writer}</span>
                    </li>
                  )}

                  {product?.product_language && (
                    <li>
                      <b>Language: </b>
                      <span>{product?.product_language}</span>
                    </li>
                  )}

                  {product?.product_full_name && (
                    <li>
                      <b>Full Product Name: </b>
                      <span>{product?.product_full_name}</span>
                    </li>
                  )}

                  {/* <li>
    <b>Share on</b>
    <div className="share">
      <a href="#">
        <i className="fa fa-facebook"></i>
      </a>
      <a href="#">
        <i className="fa fa-twitter"></i>
      </a>
      <a href="#">
        <i className="fa fa-instagram"></i>
      </a>
      <a href="#">
        <i className="fa fa-pinterest"></i>
      </a>
    </div>
  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="product__details__tab">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <span className="">Description</span>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane active" id="tabs-1" role="tabpanel">
                    <div className="product__details__tab__desc">
                      {/* <h6>Products Infomation</h6> */}
                      <p>{product?.product_description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="related-product">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title related__product__title">
                <h2>Related Product</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {relatedProducts?.map((product, index) => (
              <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
                <div
                  className="product__item hover-cursor-pointer"
                  onClick={() => {
                    navigate(
                      `/shop_details/${product?.product_full_name?.replace(
                        /[ /]+/g,
                        "_"
                      )}/${product?.id}`
                    );
                  }}
                >
                  <div
                    className="product__item__pic set-bg"
                    style={{
                      backgroundImage: `url(${
                        backgroundImages[product.id] || NoImage
                      })`,
                    }}
                  >
                    {/* <ul className="product__item__pic__hover">
                      <li>
                        <a href="#">
                          <i className="fa fa-heart"></i>
                        </a>
                      </li>

                      <li>
                        <a href="#">
                          <i className="fa fa-shopping-cart"></i>
                        </a>
                      </li>
                    </ul> */}
                  </div>
                  <div className="product__item__text">
                    <h6>
                      <a href="#">{product?.product_name}</a>
                    </h6>
                    <div className="price-wrapper">
                      <h5>₹&nbsp;{basePrice(product, 1)}</h5>
                      {Number(product?.product_discount) > 0 ? (
                        <>
                          <span className="original-price">
                            ₹&nbsp;{product?.product_price}
                          </span>
                          <span className="text-success">
                            {product?.product_discount}
                            {product?.product_discount_type === 0 ? (
                              <span>%</span>
                            ) : (
                              <span>₹</span>
                            )}{" "}
                            off
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <LoginModal
              show={showLoginModal}
              onHide={() => setShowLoginModal(false)}
            />
            <ToastContainer />
          </div>
        </div>
      </section>
    </>
  );
};

export default ShopDetails;
