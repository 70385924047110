import React, { useEffect, useState } from "react";
import NoImage from "../../img/noimage.png";
import useAxios from "../../utils/axiosInstance";
import { useNavigate } from "react-router-dom";
import { basePrice } from "../../utils/helpers";

const Featured = () => {
  const axios = useAxios();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState("0");
  const getFeaturedProducts = async () => {
    let url = `/products/featured_products?category=${category}`;
    try {
      const response = await axios.get(url);
      const result = response?.data;
      setProducts(result?.data);
    } catch (error) {}
  };

  const [backgroundImages, setBackgroundImages] = useState({});

  const getBaseProductImage = async (productId) => {
    try {
      const response = await axios.get(
        `/products/product_base_image/${productId}`
      );
      const base64Image = response?.data?.data;
      return base64Image ? base64Image : NoImage;
    } catch (error) {
      // console.error("Error fetching base image:", error);
      return NoImage;
    }
  };

  useEffect(() => {
    const fetchImages = async () => {
      const images = {};
      await Promise.all(
        products.map(async (product) => {
          if (product?.id) {
            const imageUrl = await getBaseProductImage(product.id);
            images[product.id] = imageUrl;
          }
        })
      );
      setBackgroundImages(images);
    };

    fetchImages();
  }, [products]);

  useEffect(() => {
    if (!!category) getFeaturedProducts();
  }, [category]);

  return (
    <section className="featured spad">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
              <h2>Featured Product</h2>
            </div>
            <div className="featured__controls">
              <ul>
                <li className="active" onClick={() => setCategory("0")}>
                  All
                </li>
                <li onClick={() => setCategory("1")}>Books</li>
                <li onClick={() => setCategory("2")}>Stationary</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row featured__filter">
          {products?.map((product, index) => (
            <div key={index} className="col-lg-3 col-md-4 col-sm-6 ">
              <div
                className="featured__item hover-cursor-pointer"
                onClick={() => {
                  navigate(
                    `/shop_details/${product?.product_full_name?.replace(
                      /[ /]+/g,
                      "_"
                    )}/${product?.id}`
                  );
                }}
              >
                <div
                  className="featured__item__pic set-bg"
                  style={{
                    backgroundImage: `url(${
                      backgroundImages[product.id] || NoImage
                    })`,
                  }}
                ></div>
                <div className="featured__item__text">
                  <h6>
                    <span>{product?.product_name}</span>
                  </h6>
                  <div className="price-wrapper">
                    <h5>₹&nbsp;{basePrice(product, 1)}</h5>
                    {Number(product?.product_discount) > 0 ? (
                      <>
                        <span className="original-price">
                          ₹&nbsp;{product?.product_price}
                        </span>
                        <span className="text-success">
                          {product?.product_discount}
                          {product?.product_discount_type === 0 ? (
                            <span>%</span>
                          ) : (
                            <span>₹</span>
                          )}{" "}
                          off
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Featured;
