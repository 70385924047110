import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeAllCartItems } from "../../reduxStore/Cart";
import { removeWishlist } from "../../reduxStore/Wishlist";
import { removeLoggedInInfo } from "../../reduxStore/Auth";
import LoginModal from "../../pages/auth/login/LoginModal";

const Header = () => {
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const { isLoggedIn, user } = useSelector((state) => state.Auth);
  const { wishlistLength } = useSelector((state) => state.Wishlist);
  const { cartLength, total } = useSelector((state) => state.Cart);
  const { setting } = useSelector((state) => state.Setting);
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [showLoginModal, setShowLoginModal] = useState(false);


  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const openButton = document.querySelector(".humberger__open");
    const menuWrapper = document.querySelector(".humberger__menu__wrapper");
    const overlay = document.querySelector(".humberger__menu__overlay");
    const body = document.body;

    const openMenu = () => {
      menuWrapper.classList.add("show__humberger__menu__wrapper");
      overlay.classList.add("active");
      body.classList.add("over_hid");
    };

    const closeMenu = () => {
      menuWrapper.classList.remove("show__humberger__menu__wrapper");
      overlay.classList.remove("active");
      body.classList.remove("over_hid");
    };

    openButton.addEventListener("click", openMenu);
    overlay.addEventListener("click", closeMenu);

    return () => {
      openButton.removeEventListener("click", openMenu);
      overlay.removeEventListener("click", closeMenu);
    };
  }, []);

  const logoutHandler = () => {
    localStorage.removeItem("token");
    dispatch(removeAllCartItems());
    dispatch(removeWishlist());
    dispatch(removeLoggedInInfo());
  };

  return (
    <header className="header">
      <div className="header__top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="header__top__left">
                <ul>
                  <li>
                    <i className="fa fa-envelope"></i> {setting?.email}
                  </li>
                  {/* <li>Free Shipping for all Order of $99</li> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="header__top__right">
                {/* <div className="header__top__right__social">
                  <a href="#">
                    <i className="fa fa-facebook"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-linkedin"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-pinterest-p"></i>
                  </a>
                </div> */}
                {/* <div className="header__top__right__language">
                  <img src={LanguageImage} alt="" />
                  <div>English</div>
                  <span className="arrow_carrot-down"></span>
                  <ul>
                    <li>
                      <a href="#">Spanis</a>
                    </li>
                    <li>
                      <a href="#">English</a>
                    </li>
                  </ul>
                </div> */}
                <div className="header__top__right__auth" ref={dropdownRef}>
                  {isLoggedIn ? (
                    <div
                      className="nav-item dropdown"
                      onClick={() => setDropdownOpen(!dropdownOpen)}
                      style={{ cursor: "pointer" }}
                    >
                      <span className="nav-link dropdown-toggle" role="button">
                        <i
                          className="fa fa-user"
                          style={{ marginRight: "5px" }}
                        ></i>
                        {user?.client_name?.length > 15
                          ? `${user.client_name.slice(0, 15)}...`
                          : user.client_name}
                      </span>
                      {dropdownOpen && (
                        <ul className="dropdown-menu show ddown">
                          <li className="active">
                            <Link className="dropdown-item" to="/profile">
                              <i
                                className="fa fa-user-circle-o"
                                style={{ marginRight: "5px" }}
                              ></i>
                              My Profile
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/profile/favourite"
                            >
                              <i
                                className="fa fa-heart"
                                style={{ marginRight: "5px" }}
                              ></i>
                              Wishlist
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/profile/orders"
                            >
                              <i
                                className="fa fa-shopping-bag"
                                style={{ marginRight: "5px" }}
                              ></i>
                              Orders
                            </Link>
                          </li>
                          <li onClick={logoutHandler}>
                            <span className="dropdown-item">
                              <i
                                className="fa fa-sign-out"
                                style={{ marginRight: "5px" }}
                              ></i>
                              Logout
                            </span>
                          </li>
                        </ul>
                      )}
                    </div>
                  ) : (
                    // <Link to="/auth/login">
                    //   <i className="fa fa-user"></i> Login
                    // </Link>
                      <button className="btn btn-outline-primary" onClick={() => setShowLoginModal(true)}>Login</button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="header__logo">
              <a href="/">
                <img src={setting?.image_url} alt="" />
              </a>
            </div>
          </div>
          <div className="col-lg-6">
            <nav className="header__menu">
              <ul>
                <li className={`${pathname === "/" ? "active" : ""}`}>
                  <a href="/">Home</a>
                </li>
                <li
                  className={`${pathname === "/shopping_cart" ? "active" : ""}`}
                >
                  <Link to="/shopping_cart">Cart</Link>
                </li>
                <li className={`${pathname === "/contact" ? "active" : ""}`}>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="col-lg-3">
            <div className="header__cart">
              <ul>
                <li>
                  <Link to={"/wish_list"}>
                    <i className="fa fa-heart"></i>{" "}
                    <span>{wishlistLength}</span>
                  </Link>
                </li>
                <li>
                  <Link to={"shopping_cart"}>
                    <i className="fa fa-shopping-bag"></i>{" "}
                    <span>{cartLength}</span>
                  </Link>
                </li>
              </ul>
              <div className="header__cart__price">
                item: <span>₹&nbsp;{total}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="humberger__open">
          <i className="fa fa-bars"></i>
        </div>

        <LoginModal
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
      />
      </div>
    </header>
  );
};

export default Header;
