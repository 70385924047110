import React from "react";
import Cat1 from "../../img/categories/cat-1.jpg";
import Cat2 from "../../img/categories/cat-2.jpg";
import Cat3 from "../../img/categories/cat-3.jpg";
import Cat4 from "../../img/categories/cat-4.jpg";
import Cat5 from "../../img/categories/cat-5.jpg";
const Categories = () => {
  return (
    <section className="categories">
      <div className="container">
        <div className="row">
          <div className="categories__slider owl-carousel">
            <div className="col-lg-3">
              <div
                className="categories__item set-bg"
                style={{ backgroundImage: `url(${Cat1})` }}
              >
                <h5>
                  <a href="#">Fresh Fruit</a>
                </h5>
              </div>
            </div>
            <div className="col-lg-3">
              <div
                className="categories__item set-bg"
                style={{ backgroundImage: `url(${Cat2})` }}
              >
                <h5>
                  <a href="#">Dried Fruit</a>
                </h5>
              </div>
            </div>
            <div className="col-lg-3">
              <div
                className="categories__item set-bg"
                style={{ backgroundImage: `url(${Cat3})` }}
              >
                <h5>
                  <a href="#">Vegetables</a>
                </h5>
              </div>
            </div>
            <div className="col-lg-3">
              <div
                className="categories__item set-bg"
                style={{ backgroundImage: `url(${Cat4})` }}
              >
                <h5>
                  <a href="#">drink fruits</a>
                </h5>
              </div>
            </div>
            <div className="col-lg-3">
              <div
                className="categories__item set-bg"
                style={{ backgroundImage: `url(${Cat5})` }}
              >
                <h5>
                  <a href="#">drink fruits</a>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Categories;
