const basePrice = (product, quantity = 1) => {
  // Destructure product properties
  const {
    tax_include,
    product_price,
    tax_percentage,
    product_discount_type,
    product_discount,
  } = product;

  // Convert values to floating-point numbers
  const price = parseFloat(product_price) || 0;
  const taxRate = (parseFloat(tax_percentage) || 0) / 100;
  const discountVal = parseFloat(product_discount) || 0;

  // Calculate base price based on tax inclusion
  const rate = Number(tax_include) === 1 ? price / (1 + taxRate) : price;

  // Calculate net taxable amount based on discount type
  const discountAmount =
    Number(product_discount_type) === 1
      ? discountVal // Fixed discount
      : (rate * discountVal) / 100; // Percentage discount

  // Compute the net taxable value
  const net = parseFloat(rate - discountAmount).toFixed(2);
  const netTaxableValue = parseFloat(net * 1).toFixed(2);

  const taxAmount = parseFloat(netTaxableValue * taxRate).toFixed(2);

  const mainRowAmount = parseFloat(
    (parseFloat(taxAmount) + parseFloat(netTaxableValue)) * quantity
  ).toFixed(2);

  // Return the net taxable value, parsed to 2 decimal places
  return parseFloat(mainRowAmount).toFixed(2);
};

export { basePrice };
