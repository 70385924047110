import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from "../../img/banner/banner-1.jpg"; // Correct way to import the image

// Static category data with valid image paths
const categories = [
  { id: 1, name: "Category 1", image: image1 },
  { id: 2, name: "Category 2", image: image1 },
  { id: 3, name: "Category 3", image: image1 },
  { id: 4, name: "Category 4", image: image1 },
  { id: 5, name: "Category 5", image: image1 },
];

const outerSliderSettings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      },
    },
  ],
};

function CategorySlider() {
  return (
    <div className="section" style={{ marginTop: "0px", paddingTop: "0px" }}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Slider {...outerSliderSettings}>
              {categories.map((category) => (
                <div key={category.id} className="category-item">
                  <div className="img-slider" style={{ position: "relative", width: "100%" }}>
                    <img
                      src={category.image}
                      alt={category.name}
                      className="img-fluid"
                      style={{
                        width: "100%",
                        height: "250px",
                        objectFit: "cover",
                      }}
                    />
                    <div className="category-name-overlay">
                      <h4>{category.name}</h4>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategorySlider;
