import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumb from "../../img/breadcrumb.jpg";
import { useDispatch, useSelector } from "react-redux";
import useAxios from "../../utils/axiosInstance";
import Loader from "../../components/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {initialCartState} from "../../reduxStore/Cart";


const Checkout = () => {
  const axios = useAxios();
  const { cartProducts, subTotal, total } = useSelector((state) => state.Cart);
  const { user } = useSelector((state) => state.Auth);
  const [allActiveStates, setAllActiveStates] = useState([]);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleStateChange = (e) => {
    const { name, value } = e.target;
    setFormData((pre) => ({ ...pre, [name]: value }));
    const result = allActiveStates.find((state) => state.tin === value);
    if (result) {
      setFormData((pre) => ({ ...pre, client_state: result.state_name }));
    } else {
      setFormData((pre) => ({ ...pre, client_state: "" }));
    }
  };

  const handlePlaceOrder = async (e) => {
    e.preventDefault();
    setButtonDisabled(true); 
    const placed_order = cartProducts?.map((product) => ({
      id: product.id,
      quantity: product.quantity,
    }));

    const userData = formData;

    console.log(formData);
    

    try {
      const response = await axios.post("/order/create_order", {
        placed_order,
        userData,
      });
      const { currency, id, amount } = response?.data.order;
      setButtonDisabled(false);
      const options = {
        key: "rzp_test_6MTLjFzCfZKHOk",
        amount: amount,
        currency: currency,
        name: "Your Store Name",
        description: "Test Transaction",
        image: "/your_logo.png",
        order_id: id,
        handler: async function (response) {
          // Handle successful payment
          setLoading(true);
          try {
            const verification = await axios.post("/order/payment_varification", {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
            });
            console.log("Payment verified successfully", verification.data);
            setLoading(false);
            toast("Payment verified successfully");
            getCartItems();
            navigate("/profile/orders"); 
            // window.location.reload();
          } catch (verificationError) {
            console.error("Payment verification failed", verificationError);
            toast("Payment verified failed");
            setLoading(false);
            setButtonDisabled(false);
          }
        },
        prefill: {
          name: formData.client_name,
          email: formData.client_email,
          contact: formData.client_phone,
        },
        notes: {
          address: formData.client_address,
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Error placing order:", error);
      setError("Failed to place order. Please try again.");
      setButtonDisabled(false);
    }
  };

  const getCartItems = async () => {
    try {
      const response = await axios.get("/cart/get_cart_items");
      const result = response?.data;
      dispatch(initialCartState({ cartProducts: result?.data || [] }));
    } catch (error) {}
  };

  useMemo(() => {
    setFormData({
      client_name: user?.client_name,
      client_country: user?.client_country,
      client_address: user?.client_address,
      client_city: user?.client_city,
      client_state: user?.client_state,
      client_state_code: user.client_state_code,
      client_zipcode: user?.client_zipcode,
      client_phone: user?.client_phone,
      client_email: user?.client_email,
    });
  }, [user]);

  const getAllActiveStates = async () => {
    try {
      const response = await axios.get("/general/active_states");
      const result = response?.data;
      setAllActiveStates(result?.data);
    } catch (error) {}
  };

  useEffect(() => {
    getAllActiveStates();
  }, []);

  return (
    <>
     {loading && <Loader />}
      <section
        className="breadcrumb-section set-bg"
        style={{ backgroundImage: `url(${Breadcrumb})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="breadcrumb__text">
                <h2>Checkout</h2>
                <div className="breadcrumb__option">
                  <Link to="/">Home</Link>
                  <span>Checkout</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="checkout spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {error && (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  {error}
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    onClick={() => setError("")}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="checkout__form">
            <h4>Billing Details</h4>
            <form onSubmit={handlePlaceOrder}>
              <div className="row">
                <div className="col-lg-8 col-md-6">
                  <div className="checkout__input">
                    <p>
                      Name<span>*</span>
                    </p>
                    <input
                      type="text"
                      name="client_name"
                      minLength={3}
                      maxLength={224}
                      required
                      readOnly
                      value={formData?.client_name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="checkout__input">
                    <p>
                      Country<span>*</span>
                    </p>
                    <input
                      type="text"
                      name="client_country"
                      required
                      readOnly
                      value={formData?.client_country}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="checkout__input">
                    <p>
                      Address<span>*</span>
                    </p>
                    <input
                      type="text"
                      name="client_address"
                      placeholder="Street Address"
                      className="checkout__input__add"
                      required
                      value={formData?.client_address}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="checkout__input">
                    <p>
                      Town/City<span>*</span>
                    </p>
                    <input
                      type="text"
                      name="client_city"
                      required
                      value={formData?.client_city}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="checkout__input">
                    <p>
                      Country/State<span>*</span>
                    </p>
                    <select
                      className="custom-select"
                      name="client_state_code"
                      required
                      disabled
                      value={formData?.client_state_code || ""}
                      onChange={handleStateChange}
                    >
                      <option value="" disabled>
                        State
                      </option>
                      {allActiveStates?.map((state) => (
                        <option key={state.tin} value={state.tin}>
                          {state.state_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="checkout__input">
                    <p>
                      Postcode / ZIP<span>*</span>
                    </p>
                    <input
                      type="text"
                      name="client_zipcode"
                      pattern="^[1-9][0-9]{5}$"
                      title="Please enter a valid 6-digit zip code starting with a non-zero digit."
                      maxLength={6}
                      required
                      value={formData?.client_zipcode}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="checkout__input">
                    <p>
                      Order notes<span></span>
                    </p>
                    <input
                      type="text"
                      name="order_notes"
                      placeholder="Notes about your order, e.g. special notes for delivery."
                      
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="checkout__input">
                    <p>
                      Phone<span>*</span>
                    </p>
                    <input
                      type="text"
                      name="client_phone"
                      pattern="^[6-9]\d{9}$"
                      title="Please enter a valid 10-digit phone number"
                      maxLength={10}
                      required
                      readOnly
                      value={formData?.client_phone}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="checkout__input">
                    <p>
                      Email<span>*</span>
                    </p>
                    <input
                      type="text"
                      name="client_email"
                      pattern="^(?!.*\.\.)[^\s@]+@[^\s@]+\.[^\s@]+(?<!\.)$"
                      title="Please enter a valid email format, no double dots, and no trailing dot."
                      required
                      readOnly
                      value={formData?.client_email}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="checkout__order">
                    <h4>Your Order</h4>
                    <div className="checkout__order__products">
                      Products <span>Total</span>
                    </div>
                    <ul>
                      {cartProducts?.map((product, index) => (
                        <li key={index}>
                          {product?.product_name} x {product?.quantity}{" "}
                          <span>{product?.price * product?.quantity}</span>
                        </li>
                      ))}
                    </ul>
                    {/* <div className="checkout__order__subtotal">
                      Subtotal <span>{subTotal}</span>
                    </div> */}
                    <div className="checkout__order__total" style={{borderTop:'1px solid #e1e1e1', paddingTop:'5px'}}>
                      Total <span>{total}</span>
                    </div>
                    <button type="submit" className="site-btn" disabled={buttonDisabled}>
                      {loading ? "Processing..." : "PLACE ORDER"}
                    </button>

                  </div>
                </div>
              </div>
            </form>
            <ToastContainer />
          </div>
        </div>
      </section>
    </>
  );
};

export default Checkout;
