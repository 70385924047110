import React, { useEffect, useState } from "react";
import HeroBanner from "../../img/hero/banner.jpg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useAxios from "../../utils/axiosInstance";

const Hero = () => {
  const axios = useAxios();
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const { setting } = useSelector((state) => state.Setting);
  const [booksSubCategory, setBooksSubCategory] = useState([]);
  const [stationarySubCategory, setStationarySubCategory] = useState([]);
  const [category, setCategory] = useState("0");
  const [searchKey, setSearchKey] = useState("");
  const [isSearched, setIsSearched] = useState(false);
  const [results, setResults] = useState([]);
  const [isCategoriesVisible, setIsCategoriesVisible] = useState(false);

  // Check if pathname is not "/"
  const isNotHomePage = location.pathname !== "/";

  // Function to toggle visibility
  const toggleCategories = () => {
    if (isNotHomePage) {
      setIsCategoriesVisible((prevVisible) => !prevVisible);
    }
  };

  const handleSearchButton = (e) => {
    e.preventDefault();
    if (!searchKey) {
      return;
    }
    let sub_category_id = null;
    if (category === "0") {
      const result =
        booksSubCategory?.find((book) =>
          book?.category_name.includes(searchKey)
        ) ||
        stationarySubCategory?.find((item) =>
          item?.category_name.includes(searchKey)
        );
      if (result) sub_category_id = result.id;
    } else if (category === "1") {
      const result = booksSubCategory?.find((book) =>
        book?.category_name.includes(searchKey)
      );
      if (result) sub_category_id = result.id;
    } else if (category === "2") {
      const result = stationarySubCategory?.find((book) =>
        book?.category_name.includes(searchKey)
      );
      if (result) sub_category_id = result.id;
    }
    if (sub_category_id) {
      setIsSearched(true);
      navigate(`/shop_grid/${searchKey}/${sub_category_id}`);
    } else if (results?.length) {
      const result = results[0];
      setIsSearched(true);
      navigate(
        `/shop_details/${result?.product_full_name?.replace(/[ /]+/g, "_")}/${
          result?.id
        }`
      );
    }
  };

  const handleSearch = async () => {
    // Build the query string manually
    let url = `/general/search?category=${category}`;
    if (searchKey) {
      url += `&search=${searchKey}`;
    }
    try {
      const response = await axios.get(url);
      const result = response?.data;
      setResults(result?.data);
    } catch (error) {}
  };

  const getAllSubCategories = async () => {
    try {
      const response = await axios.get("/general/all_sub_categories");
      const result = response?.data;
      const { books, stationary } = result?.data?.reduce(
        (acc, item) => {
          if (item.parent_category_id === 1) acc.books.push(item);
          else if (item.parent_category_id === 2) acc.stationary.push(item);
          return acc;
        },
        { books: [], stationary: [] }
      );

      setBooksSubCategory(books);
      setStationarySubCategory(stationary);
    } catch (error) {}
  };

  useEffect(() => {
    getAllSubCategories();
  }, []);

  useEffect(() => {
    if (searchKey) {
      handleSearch();
      // Cleanup the timeout when the component is unmounted or when the dependencies change
      return () => {};
    }
  }, [category, searchKey]);

  // Effect to hide categories when on the homepage
  useEffect(() => {
    if (!isNotHomePage) {
      setIsCategoriesVisible(true);
    } else {
      setIsCategoriesVisible(false);
    }
  }, [isNotHomePage]);

  return (
    <section className={`hero ${pathname !== "/" ? "hero-normal" : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="hero__categories">
              <div className="hero__categories__all" onClick={toggleCategories}>
                <i className="fa fa-bars"></i>
                <span>All departments</span>
              </div>
              <ul style={{ display: isCategoriesVisible ? "block" : "none" }}>
                <li>
                  <a href="#">
                    <strong>Books</strong>
                  </a>
                </li>
                {booksSubCategory?.map((bookSubCat) => (
                  <li
                    key={bookSubCat.id}
                    onClick={() => {
                      setIsCategoriesVisible(false);
                      navigate(
                        `/shop_grid/${bookSubCat?.url_key}/${bookSubCat?.id}`
                      );
                    }}
                  >
                    <span>{bookSubCat.category_name}</span>
                  </li>
                ))}

                <li>
                  <a href="#">
                    <strong>Stationary</strong>
                  </a>
                </li>
                {stationarySubCategory?.map((stationarySubCat) => (
                  <li
                    key={stationarySubCat.id}
                    onClick={() => {
                      setIsCategoriesVisible(false);
                      navigate(
                        `/shop_grid/${stationarySubCat?.url_key}/${stationarySubCat?.id}`
                      );
                    }}
                  >
                    <span>{stationarySubCat.category_name}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="hero__search">
              <div className="hero__search__form">
                <form onSubmit={handleSearchButton}>
                  <div className="hero__search__categories">
                    <select
                      name="category"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="0">All Categories</option>
                      <option value="1">Books</option>
                      <option value="2">Stationary</option>
                    </select>
                  </div>
                  <input
                    type="text"
                    placeholder="What do yo u need?"
                    onChange={(e) => {
                      setIsSearched(false);
                      setSearchKey(e.target.value);
                    }}
                    value={searchKey || ""}
                    onFocus={() => setIsSearched(false)}
                  />
                  <button
                    type="submit"
                    className="site-btn"
                    onClick={handleSearchButton}
                  >
                    SEARCH
                  </button>
                </form>
              </div>
              {!!searchKey && !isSearched ? (
                <div className="search_result">
                  <ul>
                    {results?.map((result, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setIsCategoriesVisible(false);
                          setIsSearched(true);
                          navigate(
                            result?.category_name
                              ? `/shop_grid/${result?.category_name?.replace(
                                  /[ /]+/g,
                                  "_"
                                )}/${result?.id}`
                              : `/shop_details/${result?.product_full_name?.replace(
                                  /[ /]+/g,
                                  "_"
                                )}/${result?.id}`
                          );
                        }}
                      >
                        <i className="fa fa-search"></i>&nbsp;&nbsp;&nbsp;
                        <span>
                          {result?.category_name && (
                            <b style={{ fontWeight: "500px" }}>
                              {result.category_name}
                            </b>
                          )}{" "}
                          {/* Displays category_name separately */}
                          {[
                            result?.product_name,
                            result?.subject,
                            result?.language,
                            result?.writer,
                            result?.board,
                          ]
                            .filter((item) => item) // Filters out any undefined or empty fields
                            .join(" | ")}{" "}
                          {/* Joins available fields with " | " separator */}
                        </span>
                      </li>
                    ))}
                    {results?.length === 0 ? (
                      <li>
                        <span>No results found</span>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              ) : (
                ""
              )}

              <div className="hero__search__phone">
                <div className="hero__search__phone__icon">
                  <i className="fa fa-phone"></i>
                </div>
                <div className="hero__search__phone__text">
                  <h5>+91 {setting?.telephone}</h5>
                  <span>support 24/7 time</span>
                </div>
              </div>
            </div>
            {pathname === "/" && (
              <div
                className="hero__item set-bg"
                style={{ backgroundImage: `url(${HeroBanner})` }}
              >
                <div className="hero__text">
                  <span>BOOKS</span>
                  <h2>
                    Books <br />
                    100% Originals
                  </h2>
                  <p>Free Pickup and Delivery Available</p>
                  <a href="#" className="primary-btn">
                    SHOP NOW
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
