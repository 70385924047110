import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAxios from "../../../utils/axiosInstance";
import { useDispatch } from "react-redux";
import { login } from "../../../reduxStore/Auth";

const LoginModal = ({ show, onHide }) => {
  const location = useLocation();
  const redirect = new URLSearchParams(location.search).get("redirect");
  const axios = useAxios();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);

  const handleSubmit = async (e) => {
    setError("");
    e.preventDefault();
    setBusy(true);
    try {
      const response = await axios.post("/auth/login", formData);
      const result = response?.data;
      setFormData({});
      localStorage.setItem("token", result?.token);
      dispatch(login({ user: result?.data }));
      //   if (redirect) {
      //     navigate(`/${redirect}`);
      //   } else {
      //     navigate("/");
      //   }
      onHide(); // Close the modal after successful login
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message ||
        "Something went wrong! Please try again.";
      setError(errorMessage);
    } finally {
      setBusy(false);
    }
  };

  return (
    <>
      {show && (
        <div className="modal-backdrop fade show"></div> // Background dim
      )}
      <div
        className={`modal fade ${show ? "show d-block" : ""}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="loginModalLabel"
        aria-hidden="true"
        style={{ display: show ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="loginModalLabel">
                Login In to <span className="font-weight-bold">Edutools</span>
              </h5>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={onHide}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                {error && (
                  <div
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    {error}
                    <button
                      type="button"
                      className="close"
                      aria-label="Close"
                      onClick={() => setError("")}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                )}
                <div className="form-group">
                  <label htmlFor="client_username">Username / Email <span style={{color:'red'}}>*</span></label>
                  <input
                    type="text"
                    className="form-control"
                    id="client_username"
                    name="client_username"
                    required
                    value={formData?.client_username || ""}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        client_username: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password <span style={{color:'red'}}>*</span></label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    required
                    value={formData?.password || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, password: e.target.value })
                    }
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <button
                    className="btn btn-link"
                    onClick={(e) => {
                      e.stopPropagation();
                      onHide();
                      navigate("/auth/forgot");
                    }}
                  >
                    Forgot Password?
                  </button>
                </div>
                <button
                  type="submit"
                  className="btn btn-success btn-block text-white"
                  disabled={busy}
                >
                  Log In
                </button>
              </form>
            </div>
            <div className="modal-footer">
              <span>
                Don't have an account?{" "}
                <button
                  className="btn btn-link"
                  onClick={() => {
                    onHide();
                    navigate("/auth/register");
                  }}
                >
                  Sign up here
                </button>
              </span>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onHide}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginModal;
