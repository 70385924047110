import React, { useEffect, useState } from "react";
import useAxios from "../../../utils/axiosInstance";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const axios = useAxios();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ client_country: "IN" });
  const [allActiveStates, setAllActiveStates] = useState([]);
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState("");

  const handleStateChange = (e) => {
    const { name, value } = e.target;
    setFormData((pre) => ({ ...pre, [name]: value }));
    const result = allActiveStates.find((state) => state.tin === value);
    if (result) {
      setFormData((pre) => ({ ...pre, client_state: result.state_name }));
    } else {
      setFormData((pre) => ({ ...pre, client_state: "" }));
    }
  };

  const handleSubmit = async (e) => {
    setError("");
    e.preventDefault();
    setBusy(true);
    try {
      const response = await axios.post("/auth/register", formData);
      const result = response?.data;
      alert(
        "Registration completed! Please log in using the email or username, along with the password sent to your registered email."
      );
      navigate("/auth/login");
      setFormData({});
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message ||
        "Something went wrong! Please try again";
      setError(errorMessage);
    } finally {
      setBusy(false);
    }
  };

  const getAllActiveStates = async () => {
    try {
      const response = await axios.get("/general/active_states");
      const result = response?.data;
      setAllActiveStates(result?.data);
    } catch (error) {}
  };

  useEffect(() => {
    getAllActiveStates();
  }, []);

  return (
    <>
      <section className="container">
        <div className="row ">
          <div className="col-md-12">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-xl-6 col-md-8">
                <div className=" text-black">
                  <h3 className="mb-4 mt-4">
                    Register In to <span className="fw-bold">Edutools</span>
                  </h3>
                  <form onSubmit={handleSubmit}>
                    {error && (
                      <div
                        className="alert alert-danger alert-dismissible fade show"
                        role="alert"
                      >
                        {error}
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                          onClick={() => setError("")}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-md-12 mb-4">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="client_name">
                            Name
                          </label>
                          <input
                            type="text"
                            id="client_name"
                            className="form-control"
                            name="client_name"
                            minLength={3}
                            maxLength={224}
                            required
                            value={formData?.client_name || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                client_name: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-6 mb-4">
                      <div  className="form-outline">
                        <label className="form-label" htmlFor="form3Example1n">
                          Last name
                        </label>
                        <input
                          type="text"
                          id="form3Example1n"
                          className="form-control"
                        />
                      </div>
                    </div> */}
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="client_email">
                            Email
                          </label>
                          <input
                            type="email"
                            id="client_email"
                            className="form-control"
                            required
                            name="client_email"
                            pattern="^(?!.*\.\.)[^\s@]+@[^\s@]+\.[^\s@]+(?<!\.)$"
                            title="Please enter a valid email format, no double dots, and no trailing dot."
                            value={formData?.client_email || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                client_email: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="client_phone">
                            Phone
                          </label>
                          <input
                            type="tel"
                            id="client_phone"
                            className="form-control"
                            name="client_phone"
                            pattern="^[6-9]\d{9}$"
                            title="Please enter a valid 10-digit phone number"
                            maxLength={10}
                            required
                            value={formData?.client_phone || ""}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                client_phone: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="client_address">
                        Address
                      </label>
                      <input
                        type="text"
                        id="client_address"
                        name="client_address"
                        className="form-control"
                        value={formData?.client_address || ""}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            client_address: e.target.value,
                          });
                        }}
                      />
                    </div>

                    <div className="row">
                      <div className="col-md-2 mb-4">
                        <input
                          className="form-control"
                          readOnly
                          defaultValue={formData?.client_country}
                        />
                      </div>
                      <div className="col-md-6 mb-4">
                        <select
                          className="custom-select"
                          name="client_state_code"
                          required
                          value={formData?.client_state_code || ""}
                          onChange={handleStateChange}
                        >
                          <option value="" disabled>
                            State
                          </option>
                          {allActiveStates?.map((state) => (
                            <option key={state.tin} value={state.tin}>
                              {state.state_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-4 mb-4">
                        <input
                          type="text"
                          id="client_city"
                          name="client_city"
                          className="form-control"
                          placeholder="City"
                          value={formData?.client_city || ""}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              client_city: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="client_zipcode">
                        Pincode
                      </label>
                      <input
                        type="tel"
                        id="client_zipcode"
                        name="client_zipcode"
                        className="form-control"
                        pattern="^[1-9][0-9]{5}$"
                        title="Please enter a valid 6-digit zip code starting with a non-zero digit."
                        maxLength={6}
                        value={formData?.client_zipcode || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            client_zipcode: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="client_gst_number">
                        GSTIN
                      </label>
                      <input
                        type="tel"
                        id="client_gst_number"
                        name="client_gst_number"
                        className="form-control"
                        pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}Z[0-9A-Z]{1}$"
                        title="GST number format: 11AAAAA1111A1Z1"
                        maxLength={15}
                        value={formData?.client_gst_number || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            client_gst_number: e.target.value.toUpperCase(),
                          })
                        }
                      />
                    </div>

                    <div className="d-flex justify-content-end pt-2 mb-5">
                      <input
                        type="submit"
                        value="Register"
                        className="btn text-white btn-block btn-success"
                        disabled={busy}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;
